

//banner 

.home-banner {
    height: 415px;
    border-bottom: 8px solid var(--secondary-color);
}


// background image banner with text

.bkg-img-banner {
    min-height: 500px;

    .container {
        min-height: 500px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }

    .txt-block {
        max-width: 500px;
    }
}

@media (max-width: 990.9px) {
    .bkg-img-banner .txt-block {
        max-width: 500px;
        background-color: #ffffffd4;
        padding: 15px;
    }
}


//How it works

.green-num {
    border: 2px solid var(--primary-color);
    border-radius: 50%;
    padding: 6px;
    width: 45px;
    height: 45px;
    color:  var(--primary-color);
    font-size: 20px;
    text-align: center;
}


.hw-step {
    margin-top: 10px;
    margin-bottom: 10px;

    p {
        margin-bottom: 0;
    }
}

@media (max-width: 767.9px) {
    .hw-step {
        text-align: center;
        margin: 30px 0;

        .green-num {
            margin: auto;
            margin-bottom: 10px;
        }
    }
}


//featured collection

.featured-bkg {
    height: 220px;
    position: absolute;
    background-color: #f7f7f7;
    width: 100%;
    top: calc(50% - 110px);
}

.mealkit-card {
    border-radius: 3px;
    background-color: white;
    .background-img {
        height: 215px;
        width: 100%;
    }
    .mealkit-txtblock {
        padding: 20px;
        height: 140px;
    }
}

//newsletter 

.newsletter-section {
    height: 265px;
}

.newsletter-block {
    max-width: 700px;
    padding: 30px 50px;
    box-shadow: 0 3px 6px #ffffff94;
    background-color: white;

    .input-group {
        display: flex;
        justify-content: space-between;

        .input-group__field {
            width: 65%;
            padding-left: 10px;
        }
    }

    h2 {
        margin-bottom: 20px;
    }
}

@media (max-width: 767.9px) {
    .newsletter-block {
        padding: 20px;
        margin: 15px !important;

        .input-group {
            justify-content: center !important;
        }
        input {
            margin-bottom: 15px;
        }
        #Contact_newsletter-email {
            width: 100%;
            height: 38px;
        }
    }
}


//desktop only breakpoint

@media (min-width: 991px) {
    .home-highlights {
        .t-2 {
            min-height: 80px;
        }
    }
    }

    .page .page-content img {
        max-width: 100%;
        height: auto;
    }