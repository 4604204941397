
.property-option {
    font-weight: 600;
}

.remove-link {
    position: absolute;
    top: 10px;
    right: 15px;
    background-color: var(--light-neutral-color);
    padding: 1px 8px;
    border-radius: 50%;
    color: var(--text-color);
}

.cart-item {
    border-top: 1px solid var(--light-neutral-color);
    position: relative;

    a {
        color: var(--txt-color);
    }

    .info-title {
        width: 100%;
    }
}


.cart-qty {
    max-width: 125px;
}

@media (min-width: 768px) {
    .mobile-cart-header {
        display: none;
    }
}

@media (max-width: 767.9px) {
    .desktop-cart-header {
        display: none;
    }
}