
//alignment

.vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.txt-center {
    text-align: center;
}

.txt-left {
    text-align: left;
}

.txt-right {
    text-align: right;
}

//font styles

.white-txt {
    color: white;
}

.green-txt {
    color: var(--primary-color);
}

.red-txt {
    color: var(--secondary-color);
}

.semi-b {
    font-weight: 600 !important;
}

//background

.light-bkg {
    background-color: var(--light-neutral-color);
}

.white-bkg {
    background-color: white;
}


//border 

.border-light {
    border: 1px solid var(--light-neutral-color);
}
//image

.background-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


//padding



.p-10 {
    padding: 10px;
}
.p-15 {
    padding: 15px;
}

.p-25 {
    padding: 25px;
}

.p-40 {
    padding: 40px;
}

.py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.py-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.py-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.p-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.pa-25 {
    padding: 25px;
}

.pb-50 {
    padding-bottom: 50px;
}

.p-20 {
    padding: 20px;
}

.px-15 {
    padding-right: 15px;
    padding-left: 15px;
}

.px-20 {
    padding-right: 20px;
    padding-left: 20px;
}



//margin

.my-8 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.m-20 {
    margin: 20px;
}

.my-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.my-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.my-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.my-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.my-75 {
    margin-top: 75px;
    margin-bottom: 75px;
}
.mb-15 {
    margin-bottom: 15px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mx-20 {
    margin-right: 20px;
    margin-left: 20px;
}



//list

.list-none {
    list-style-type: none;
}



//font 

body {
    font-family: 'Open-sans', sans-serif;
    color: var(--text-color);
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

h1 {
    font-size: 38px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 24px;
}

h3 {
    font-size: 24px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 16px;
}

.t-1 {
    font-size: 20px;
}

.t-2 {
    font-size: 16px;
}

.t-3 {
    font-size: 14px;
}

a {
    color: var(--primary-color);
}

a:hover {
    color: var(--accent-color);
    text-decoration: none;
}


@media (max-width: 767.9px) {
    h1 {
        font-size: 32px !important;
    }

    h2 {
        font-size: 28px !important;
    }

    h3 {
        font-size: 22px !important;
    }

    h4 {
        font-size: 20px !important;
    }

    h5 {
        font-size: 18px !important;
    }

    .t-1 {
        font-size: 18px;
    }

    .t-2 {
        font-size: 15px;
    }

    .t-3 {
        font-size: 13px;
    }
}


//shadow
.box-shadow {
    box-shadow: 0 3px 6px #00000029;
}


html {
    padding-bottom: 0 !important;
}

.page {
    min-height: calc(100vh - 500px);
    margin-top: 25px;
    margin-bottom: 50px;
}

.header-bkg {
    height: 112px;
    opacity: .5;
    z-index: 0;
}

.hide-overflow {
    overflow: hidden;
}

.img-cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
}

.breadcrumb {
    background-color: transparent;

    a {
        color: var(--txt-color);
    }
}

//buttons

.btn {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    padding: 6px 22px;
    font-family: 'Montserrat', sans-serif;
}

.btn-primary, .btn-primary:focus {
    background-color: white;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
}

.btn-primary:hover, .btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active {
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
    color: white;
}

.btn-primary:focus, .btn-primary:active {
    box-shadow: 0 0 0 0.2rem #5396364a !important;
    border-color: var(--primary-color) !important;
}

.btn-secondary, .shopify-challenge__button {
    background-color: var(--secondary-color);
    color: white;
    border: 2px solid var(--secondary-color);
}

.btn-secondary:hover, .shopify-challenge__button:hover {
    background-color: white;
    color: var(--secondary-color);
    border: 2px solid var(--secondary-color);
}

.btn-grey {
    background-color: white;
    color: #C9C7C7;
    border: 2px solid #C9C7C7;  
}

.btn-grey:hover {
    background-color: #C9C7C7;
    color: white;
    border: 2px solid #C9C7C7;  
}

.btn-txt-red {
    color: var(--secondary-color);
    padding: 0;
}

.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem #ffffff00;
}


//container

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1280px;
        padding-right: 20px;
        padding-left: 20px;
    }
}

@media (min-width: 1320px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1400px;
        padding-right: 20px;
        padding-left: 20px;
    }
}

@media (max-width: 1199.9px) {
    .container, .container-md, .container-sm {
        max-width: unset;
        padding-right: 15px;
        padding-left: 15px;
    }
}



//ordering

@media (min-width: 991px) {
    .d-order-1 {
        order: 1;
    }

    .d-order-2 {
        order: 2;
    }

    .desktop-flex {
        display: flex;
    }
}

@media (min-width: 768px) and (max-width: 990.9px) {
    .tablet-flex {
        display: flex;
    }
} 