
.collection-sort {
    border-top: 1px solid var(--light-neutral-color);
    border-bottom: 1px solid var(--light-neutral-color);
    margin-top: 25px;
    margin-bottom: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
}

//product in collection

.collection-product {
    margin-top: 20px;
}

.collection-product-title {
    padding-bottom: 20px;
}

    .collection-product .cp-title {
    font-size: 17px;
    }

    .collection-product .cp-image {
        min-height: 250px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .collection-product .cp-price {
        font-weight: 500;
        font-size: 16px;
        color: #222;
        padding-top: 5px;
    }

    .collection-product .cp-rating {
        margin-bottom: 5px;
        font-weight: 400;
        color: #212529;
        font-size: 14px;
    }
     
    .collection-product .cp-title, .collection-product .cp-title a {
        color: #222;
        font-weight: 400;
    }

    .collection-product {
        padding: 20px;
        transition: .3s;
    }

    
    .collection-product:hover {
        transform: translateY(-4px);
        transition: .3s;
        box-shadow: 0 22px 22px #0000002b;
    }

    
#SortBy {
    border: 1px solid #ced4da;
    padding: 5px;
    border-radius: 3px;
}

.only-one-variant {
    display: none;
}