//customer pages

.customer-container {
    max-width: 600px !important;
    padding: 50px 20px;
}

//captcha page

.shopify-challenge__container {
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: calc(100vh - 500px);
}

//form fields

.form-field:focus {
    color: #212529;
    background-color: #fff;
    border-color: #333333;
    outline: 0;
    box-shadow: 0 0 0 0.1rem #3333332b;
}

.form-field { 
    display: block;
    margin-bottom: 15px;
    max-width: 100%;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: auto;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

//account page

.order-titles {
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: var(--light-neutral-color);
}

.customer-order-row {
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid var(--light-neutral-color);
}

.account h1 {
    margin-bottom: 0;
}


.btn-return-account {
    background-color: #f7f7f7;
    font-weight: 600;

}

.btn-return-account:hover {
    background-color: #333;
    color: white;
}

.order-detail-title {
    font-weight: 500;
    border-bottom: 1px solid #f7f7f7;
    padding-bottom: 5px;
}


.line-item-title {
    max-width: 500px;
}


@media (min-width: 768px) {
    .mobile-order-title {
        display: none;
    }
}

@media (max-width: 767.9px) {
    .order-titles, .order-detail-title {
        display: none;
    }

    .btn-return-account {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .activate-btns {
        .btn {
            width: 100%;
            margin-bottom: 15px;
        }
    }
    .week-number {
        margin-top: 15px;
    }

    .order-details {
        margin-bottom: 15px;
    }
    .mobile-order-title {
        font-weight: 600;
    }
    .sub-total {
        padding-right: 10px;
        padding-left: 10px;
    }
}



@media (min-width: 991px) {
    .line-item-row, .order-detail-titles {
        .col-lg-1 {
            flex: 0 0 12.33%;
            max-width: 12.33%;
        }

        .col-lg-2 {
            flex: 0 0 13%;
            max-width: 13%;
        }
    }
}