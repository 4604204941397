
//announcement banner
.announcement-banner {
    background-color: var(--light-neutral-color);

    p {
        margin-bottom: 0;
        white-space: break-spaces;
    }
}

@media (max-width: 1159.9px) {
    .announcement-banner {
        background-color: var(--primary-color);
        color: white;

        .glide__arrow {
            color: white;
        }
    }
}

/*
@media (min-width: 768px) and (max-width: 1161px) {
    .announcement-banner {
        position: absolute;
        z-index: 10;
        top: 100%;
        width:100%;
        color: white;
        background-color: var(--primary-color);
    }

    #shopify-section-header {
        position: relative;
    }
}

*/
.glide__arrow {
    padding: 0;
    border: none;
    font-size: 20px;
    color: black;
    box-shadow: none;
    background-color: transparent;
}

.arrow-right {
    right: 0;
}

.announcement-container {
    width: calc(100% - 200px);
    margin-left: 200px;
}

//nav links

.main-nav {
    padding-left: 200px;
}

.main-nav li a {
    color: var(--txt-color);
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
}

.main-nav li a:hover {
    color: var(--primary-color);
    text-decoration: none;
}

@media (max-width: 1159px) {
    .main-nav {
        display: none !important;
    } 

    .announcement-container {
        width: 100% !important;
        margin: 0 !important;
    }
}

@media (min-width: 1159.9px) {
    .mobile-bkg {
        display: none !important;
    }
}


//cart icon

.cart-count {
    background-color: var(--secondary-color);
    color: #fff;
    border-radius: 50%;
    right: -10px;
    padding: 1px 5px;
    font-size: 13px;
    width: 22px;
    height: 22px;
    bottom: 5px;
    text-align: center;
}

.cart-container {
    width: 47px;
    height: 60px; 
  //  margin-right: 10px;
}


//logo

.store-logo {
    padding: 15px;
    background-color: white;
    border-radius: 0px 0px 3px 3px;
    left: 20px;
    z-index: 2;
}

.sticky {
    top: 0;
    width: 100%;
    z-index: 9999;
}

@media (max-width: 1159.9px) {
    .store-logo {
        display: none;
    }

    .mobile-store-logo {
        position: absolute;
        background-color: white;
        padding: 10px 15px;
        left: 50%;
        top: 0;
        width: 131px;
     //   transition: .3s;
        border-radius: 0 0 3px 3px;
        transform: translateX(-50%);
        z-index: 10000;
    }

    .sticky .mobile-store-logo {
        width: 90px;
     //   transition: .3s;
    }
}

//footer

.footer-container {
    max-width: 1100px;
    margin: auto;
    padding: 0 40px;

    a {
        color: var(--txt-color);
    }
}

.trademark {
    margin-top: 50px;
    margin-bottom: 5px;
}

.footer-title {
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Montserrat';
    font-size: 19px;
    margin-bottom: 10px;
    margin-top: 25px;
}

.footer-link {
    list-style-type: none;
    margin-bottom: 10px;

    a {
        color: var(--txt-color);
    }
}

@media (max-width: 767.9px) {
    .footer-menu {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .footer-container {
        padding: 0 30px !important;
    }
}

@media (min-width: 768px) and (max-width: 990.9px) {
    .footer-menu {
        max-width: 33.3%;
        flex: 0 0 33.3%;
    }
}

@media (min-width: 991px) {
    .footer-menu {
        padding: 0 30px;
    }
}




.header-contact-item svg {
    margin-right: 6px;
}
 
 
 
 //mobile-menu


 .mobile-menu-control {
     margin-right: 15px;
 }

 .mobile-nav-container {
    position: absolute;
    width: 100%;
    padding-top: 20px;
    background-color: #666;
    height: calc(100vh - 48px);
    max-height: 100%;
    min-height: 100vh;
    overflow-y: auto;
    left: -100%;
    transition: left 0.3s ease-in-out;
    z-index: 35;

    a {
        color: white;
    }

    }

    .show-menu {
        transition: left 0.5s;
        left: 0;
    }

    .mobile-menu--opened {
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        height: 100vh;
    }


    .show-child .sub-menu {
        display: block !important;
    }


    .mobile-nav-container .menu-item-has-children > a::after {
        content: '\005E';
        float: right;
        transform: rotate(180deg);
    }


    .page-title {
        padding-top: 20px;
        padding-bottom: 15px;
    }

    @media (max-width: 990.9px) {
        .page-title {
            padding-top: 10px;
    }
}


.mobilenav-search {
    margin-right: 15px;
    margin-left: 15px;
}

.header {
    position: relative;
}

//mobile menu

.mobile-nav-links {
    padding-top: 25px;
}

.mobile-nav-links li {
    list-style-type: none;
    position: relative;
    border-bottom: 1px solid  #464646;

    a {
        display: block;
        width: 100%;
        padding: 10px;
    }
    .child-arrow {
        color: #f5f5f5;
        display: block;
        width: 20%;
        text-align: right;
        cursor: pointer;
        padding: 10px;
    }
}

.has-child-nav {
    display: flex;

    a {
        width: 80%;
    }

    .child-arrow {
        width: 20%;
    }

}
//mobile menu icon

.mobilenav-toggler {
    outline: none!important;
    border: none;
    background-color: whitesmoke;
}

.mobilenav-toggler {
.mobile-menu-icon:after, .mobile-menu-icon:before, .mobile-menu-icon div {
    background-color: #333;
    content: "";
    display: block;
    height: 2px;
    margin: 5px 0;
    transition: all .2s ease-out;
}

.mobile-menu-icon {
    width: 22px;
    position: relative;
}
}

.nav-close {
    .mobile-menu-icon:before {
        transform: translateY(7px) rotate(
    45deg
    );
    }
    .mobile-menu-icon:after {
        transform: translateY(-7px) rotate(
    -45deg
    );
    }
    .mobile-menu-icon div {
        transform: scale(0);
    } 
}



.mobile-child {
    display: none;
}

.nav-arrow {
    transition: .3s;
    transform: rotate(0deg);
    position: absolute;
    right: 10px;

}


.show-nav {
    border-bottom: none !important;
    background-color: #464646;

    > .child-arrow .nav-arrow {
        transform: rotate(180deg);
        transition: .3s;
    }

    > a, > .child-arrow {
     background-color: #464646;   
    }

    li {
        border-bottom: none;
    }
    
    + .mobile-child {
        display: block;
        padding-left: 0;

        li {
            font-size: 15px;
            border-bottom: none !important;
        }
    }

    + .grandchild {
        display: block;
        padding-left: 0;
        border-bottom: 1px solid #464646;

        li {
            font-size: 15px;
            border-bottom: none !important;
            display: block;
        }
    }
}

.logo-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@media (max-width: 1199.9px) {
    .logo-container {
        flex-direction: unset;
        justify-content: space-between!important;
    }

    .mobile-bkg {
        background-color: whitesmoke;
    }
}

.mobilenav-search .search-icon {
    position: absolute;
    top: 8px;
    right: 15px;
}

.header-search .search-icon {
    position: absolute;
    top: 8px;
    left: 10px;
}