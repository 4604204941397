//recipes

.recipe-week-title {
    border-bottom: 2px solid var(--accent-color);
    margin-bottom: 15px;

    h5 {
        font-size: 17px;
        margin-bottom: 0; 
        margin-top: 7px;
    }
}

.recipe-img {
    width: 100%;
    height: 160px;
    margin-bottom: 15px;
}

.recipe-title {
    color: var(--text-color);
}


//pickup-locations

.pl-header {
    border-bottom: 3px solid var(--accent-color);
    padding-bottom: 10px;
    margin-bottom: 15px;
}

//about us

.mission {
    max-width: 720px;
    margin: auto;
    padding: 25px;
    margin-bottom: 50px;
    margin-top: 50px;
}


.about-lower {
    max-width: 750px;  
    margin-top: 50px;
    margin: auto;
    margin-top: -25px;
    padding: 0 15px;
}

.goal-icon {
    width: 125px;
    height: 125px;
    background-color: var(--primary-color);
    border-radius: 50%;
    margin: auto;

    img {
        margin-top: 30px;
    }
}

.goal-count {
    color: white;
    position: absolute;
    left: 35px;
    top: 10px;
    font-size: 24px;
}

.veg-icon {
    right: 0;
    top: 0;
    background-color: white;
    padding: 5px;
}

@media (max-width: 767.9px) {
    .goal-icon {
        margin-bottom: 40px;
    }
}



//order meal kit


.active-step {
    color: var(--primary-color);

    svg path {
        fill: var(--primary-color);
    }

    .step-line {
        background-color: var(--primary-color);
    }
}

.step-line {
    height: 1px;
    width: 45px;
    margin-right: 20px;
}



.inactive-step {
    color: #C7C7C7;

    svg path {
        fill: #C7C7C7;
    }

    .step-line {
        background-color: #C7C7C7;
    }
}

.visited-step {
    color: var(--accent-color);

    svg path {
        fill: var(--accent-color);
    }

    
    .step-line {
        background-color: var(--accent-color);
    }
}

.meal-card {
    max-width: 315px;
    width: 50%;
    min-height: 331px;
}

.meal-img {
    height: 195px;
}

.meal-img-half {
    height: 120px;
    margin-bottom: 15px;
}

.meal-img-clip1 {
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
}

.meal-img-clip2 {
    clip-path: polygon(100% 0, 0 0, 0 100%);
}

.info-link {
    justify-content: space-between;
    padding: 15px 0 0 15px;

    li {
        padding-right: 25px;

        .text-link {
            color: var(--primary-color);
            cursor: pointer;
        }
    }
}

.mk-qty {
    max-width: 100px;
    padding: 3px 10px;
}

#rove-qty {
    height: 40px;
}

#rove-btn {
    width: 200px;
}

#selected-location .semi-b {
    margin-bottom: 5px;
}

.order-week-title {
    background-color: var(--light-neutral-color);
    padding: 10px;
    color: var(--primary-color);

    h3, h5 {
        margin-bottom: 0;
    }
}

.order-by-week {
    h5 {
        color: var(--text-color);
        font-size: 18px;
    }
}


@media (max-width: 990.9px) {
    .show-infoblock {
        margin-top: 10px;
        display: block !important;   
    }

    .recipe-week-title h5 {
        margin-bottom: 15px;
    }
}



@media (min-width: 991px) {
    .info-block {
        position: absolute;
        background-color: var(--accent-color);
        margin-top: 10px;
        padding: 15px;
        color: #222;
        box-shadow: 3px 3px 6px #ececec;
}

.info-title {
    cursor: pointer;
}

.info-title:hover {
    .text-link {
        color: var(--accent-color);
    }
}
    .show-block .info-block {
        display: block !important;
    }
}

@media (max-width: 990.9px) {
    .show-block .info-block {
        display: block !important;
        margin-top: 10px;
    }
}


.mk-input {
    font-family: 'Montserrat', sans-serif;
    width: 100%;

    p {
        margin-bottom: 5px;
    }

    label {
        margin-bottom: 0;
        width: 100%;
        cursor: pointer;
    }

    input[type=radio] {
        filter: grayscale(1);
        margin-right: 10px;
    }
}

.order-mealkit-2 {
    input[type=radio] {
        margin-top: 5px;
    }  
}

.selected-input {
    background-color: #f5f5f5;
}


.selected-kit, .selected-kit:focus {
    color: white;
    background-color: var(--primary-color);
} 

.btn-primary.disabled, .btn-primary:disabled {
    color: white;  
    background-color: var(--accent-color);
    border-color: var(--accent-color);
}

//faq page

#faq-accordion {
    .faq-header {
        padding: 15px 0;

        a {
        color: var(--text-color); 

        h5 {
            margin-bottom: 0;
        }
        }
    }  
    
    .faq-body {
        margin-left: 55px;
    }

    .collapsed .faq-arrow {
        transform: rotate(0deg);
        transition: .3s;
    }

    .faq-arrow {
        transform: rotate(90deg);
        transition: .3s;
        margin-right: 10px;
        font-size: 14px;
        padding-top: 2px;
    }
}



.orderstep {
    padding: 20px 15px 0 15px;
}

@media (max-width: 767.9px) {
    #step1-icon, #step2-icon, #step3-icon {
        width: 100%;
        padding: 10px;
        justify-content: center;
    }

    .meal-card {
        width: 90%!important;
        margin: auto;
        min-height: unset;
        margin-top: 20px !important;
    }

    .step-line {
        height: 15px;
        width: 2px;
        margin: auto;
        display: none;
    }

    .btn-review-kit {
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .info-link li {
        padding-right: 25px;
        padding-bottom: 10px;
        width: 100%;
    }

    .header-bkg {
        height: 65px;
    }

    .btn-change-pickup {
        padding-top: 15px;
        width: 100%;
    }

    .mobile-review-header {
        margin-bottom: 10px;
        
        h5 {
        margin-bottom: 0;
        }
    }

    .featured-bkg {
        height: 100%;
        position: absolute;
        background-color: #f7f7f7;
        width: 100%;
        top: -25px;
    }
}



@media (max-width: 990.9px) {
    .mealkit-container {
        padding: 20px;
    }

    .mealkit-qty {
        margin-top: 15px;
        margin-bottom: 20px; 
    }
}


@media (max-width: 1169.9px) {
 .mealkit-container {
     .col-lg-8 {
         width: 100%;
     }
     .col-lg-8 {
        width: 100%;
    }
 }   
}


@media (min-width: 767.9px) {
.mobile-review-header {
    display: none;
}
}


.border-x {
    border-right: 1px solid var(--light-neutral-color);
    border-left:  1px solid var(--light-neutral-color);
    border-top:  1px solid var(--light-neutral-color);
}

.border-y {
    border-bottom:  1px solid var(--light-neutral-color);
}

.mealkit-review .review-title {
    width: 230px;
    padding-right: 15px;
}

@media (max-width: 767.9px) {
    .btn-checkout-later, .btn-checkout-now {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    #rove-btn {
        text-align: center;
        margin-top: 20px !important;
        margin: auto;
    }

    .mealkit-review h3 {
        font-size: 20px !important;
    }
}

@media (min-width: 768px) {
  .btn-checkout-later {
    margin-right: 25px;
    }  
}


.order-details {
    font-size: 14px;
    margin-top: 10px;
}

.property-option {
    font-size: 14px;
}